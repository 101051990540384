<!--报名记录———草稿  -->
<template>
    <div class="signDraft">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">报名记录(准考证)</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <div class="" style="flex: 1;">
            <div class="info">
                报名信息
            </div>
            <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="150px">
                <el-form-item label="状态">
                    <el-input v-model="form.condition" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="登记单号">
                    <el-input v-model="form.number" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input v-model="form.mechanism.mechanism" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考生姓名" prop="name">
                    <el-input v-model="form.name" placeholder="" ></el-input>
                </el-form-item>
                <el-form-item label="指导老师">
                    <el-input v-model="form.teacher" placeholder="" ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="telephone">
                    <el-input v-model="form.telephone" placeholder="" ></el-input>
                </el-form-item>
                <el-form-item label="科目">
                    <el-select v-model="form.subject" placeholder="请选择学科" :clearable="true"
                    @change="subjectChange">
                        <el-option v-for="item in form.subjectOpt" :key="item.id"
                             :label="item.course_name" :value="item.course_name">
                         </el-option>
                     </el-select>
                </el-form-item>
                <el-form-item label="原等级">
                    <el-select v-model="form.grade" placeholder="请选择原等级" :clearable="true"
                     @change="gradeChange">
                        <el-option v-for="item in form.gradeOpt" :key="item.level"
                             :label="item.abbreviation" :value="item.level">
                         </el-option>
                     </el-select>
                </el-form-item>
                <el-form-item label="报考等级" prop="applyRank">
                    <el-select v-model="form.applyRank" placeholder="请选择报考等级" :clearable="true"
                     @change="applyRankChange">
                        <el-option v-for="item in form.gradeOpt" :key="item.level"
                             :label="item.abbreviation" :value="item.level">
                         </el-option>
                     </el-select>
                </el-form-item>
                <el-form-item label="考级报名费">
                    <el-input v-model="form.regFee" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="副证费用">
                    <el-input v-model="form.viceFee" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="应缴考试费">
                    <el-input v-model="form.should" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试场次">
                    <el-input v-model="form.sessions.exam_name" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remarks" :rows="2" type="textarea" placeholder="" ></el-input>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <!-- <div class="" style="text-align: center;">
                <el-button class="edit" size="small" icon="el-icon-edit" @click="edit('form')">
                    修改报名
                </el-button>
                <el-button class="del" size="small" type="danger" icon="el-icon-delete">删除报名</el-button>
                <el-button class="Submit" size="small"  icon="el-icon-s-promotion" @click="submitForm('form')">
                    提交报名
                </el-button>
            </div> -->
        </div>
        <el-divider></el-divider>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    condition:"",//状态
                    number:"",//登记单号
                    mechanism: {mechanism: '', mechanism_id: ''},//机构名称
                    name:"",//考生姓名
                    teacher:"",//指导老师
                    telephone:"",//联系电话
                    subject:"",//科目
                    subject_id:"",//学科id
                    subjectOpt:[],//学科选项
                    grade:"",//原等级
                    gradeId: 0,
                    gradeOpt: [], //等级选项
                    applyRank:"",//报考等级
                    applyRankId: 0,
                    regFee:"",//考级报名费
                    viceFee:"",//副证费用
                    should:"",//应缴考试费
                    testSite:"",//考试场次
                    remarks:"",//备注
                    sessions: {exam_name: '',id: ''}  ,//考试场次
                    idcard:""//身份证
                },
                status:"",//状态
                rules:{
                    name: [{
                        required: true,
                        message: '考生姓名不能为空',
                        trigger: 'blur'
                    }, ],
                    telephone: [{
                        required: true,
                        message: '联系电话不能为空',
                        trigger: 'blur'
                    }, ],
                    teacher: [{
                        required: true,
                        message: '指导教师不能为空',
                        trigger: 'blur'
                    }, ],
                    applyRank: [{
                        required: true,
                        message: '报考等级不能为空',
                        trigger: 'blur'
                    }, ],
                },
                bool:false,
                flag:false
            }
        },
        created() {
            // 学科列表
            this.$request({url: '/api/studentexam/courseList',method: 'POST', data: {
            	uid: localStorage.getItem('token')
            }}).then(res => {
            	this.form.subjectOpt = res.data
                this.form.subjectOpt.forEach(item=>{
                    // console.log(item)
                    this.form.subject_id=item.id
                })
            })
            
            // 等级选项
            this.$request({url: '/api/studentexam/levelList',method: 'POST', data: {
            	uid: localStorage.getItem('token')
            }}).then(res => {
            	this.form.gradeOpt = res.data
            })
            
            // 考试场次
            this.$request({url: '/api/studentexam/examcategory',method: 'POST', data: {
            	uid: localStorage.getItem('token')
            }}).then(res => {
            	if(res.code == 1) {
            		this.form.sessions = res.data
            	}
            })
            
            //机构名称
            this.$request({url: '/api/studentexam/info',method: 'POST', data: {
            	uid: localStorage.getItem('token')
            }}).then(res => {
            	if(res.code == 1) {
            		// this.form.mechanism = res.data
                    this.form.mechanism.mechanism = res.data.mechanism
                    this.form.mechanism.mechanism_id=res.data.mechanism_id
                    // console.log(res.data.mechanism,11111)
                    this.form.idcard=res.data.idcard
            	}
            })
            
            // 初次渲染
            this.$request({url:'/api/studentexam/signupDetail',method:"POST",data:{
                uid: localStorage.getItem('token'),
                signup_id:this.$route.query.id
            }}).then(res=>{
                console.log(res)
                let data = res.data
                this.form.condition=data.status=='1'?'草稿':data.status=='2'?'报名':'准考'
                this.form.status=data.status
                this.form.number=data.certificate_no
                this.form.mechanism.mechanism_id=data.org_id
                this.form.name=data.name
                this.form.teacher=data.teacher
                this.form.telephone=data.phone
                this.form.subject=data.course
                this.form.grade=data.original_level
                this.form.applyRank=data.exam_level
                this.form.regFee=data.enroll_price
                this.form.viceFee=data.certificatecopy_price
                this.form.should= data.price1
                this.form.sessions.exam_name=data.apply
                this.form.remarks=data.remark
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            edit(formName){//修改
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                      // alert('submit!');
                      this.$request({url:'/api/studentexam/signupEdit',method:"POST",data:{
                          uid: localStorage.getItem('token'),
                          name:this.form.name,//学生姓名
                          phone:this.form.telephone,//联系方式
                          teacher:this.form.teacher,//授课老师
                          course_id:this.form.subject_id,//学科ID
                          course:this.form.subject,//科目
                          original_level:this.form.grade,//原等级值
                          level_id:this.form.applyRankId,//报考等级id
                          exam_level:this.form.applyRank,//报考等级值
                          original_level_id:this.form.gradeId,//原等级码
                          enroll_price:this.form.regFee,//考级报名费
                          price1:this.form.should,//应缴考试费
                          remark:this.form.remarks,//备注
                          signup_id:this.$route.query.id//报考记录ID
                      }}).then(res=>{
                          console.log(res)
                          if(res.code==1){
                              this.$message({
                                message: '修改成功',
                                type: 'success'
                              });
                              setTimeout(() => {
                              	this.goBack()	
                              }, 1500)
                          }else{
                              this.$message({
                                message: res.msg,
                                type: 'error'
                              });
                              setTimeout(() => {
                              	this.goBack()	
                              }, 1500)
                          }
                      }).catch(()=>{
                          this.bool=false
                      })
                    } else {
                      console.log('error submit!!');
                      return false;
                    }
                });
            },
            submitForm(formName) {//提交报名
                this.$refs[formName].validate((valid) => {
                  if (valid) {
                    // alert('submit!');
                    if(this.flag){
                        return
                    }   
                    this.flag=true
                    this.$request({url: '/api/studentexam/signupEdit', method: 'POST',data:{
                        uid: localStorage.getItem('token'),
                        signup_id:this.$route.query.id,//报考记录ID
                        status:this.form.status//状态
                    }}).then(res=>{
                        console.log(res)
                        if(res.code==1){
                            this.$message({
                              message: '提交成功',
                              type: 'success'
                            });
                            setTimeout(() => {
                            	this.goBack()	
                            }, 1500)
                        }else{
                            this.$message({
                              message: '提交失败',
                              type: 'error'
                            });
                            setTimeout(() => {
                            	this.goBack()	
                            }, 1500)
                        }
                    }).catch(()=>{
                        this.flag=false
                    })
                  } else {
                    console.log('error submit!!');
                    return false;
                  }
              });
            },
            // 学科选择
            subjectChange(e) {
            	this.form.subject = e
            },
            gradeChange(e) {
            	
            	if(e == ''){
            		this.form.applyRank = ''
            		this.form.grade = ''
            		this.form.regFee = ''
            		return
            	}
            	if(this.form.applyRank <= e && this.form.applyRank != ''){
            		this.$message({
            		  message: '原等级必须低于报考等级',
            		  type: 'warning'
            		});
            		this.form.grade = ''
            		return;
            	}
            	var leval = 4
            	if(this.form.applyRank > leval && this.form.applyRank - e > 1) {
            		this.$message({
            		  message: '原等级与报考等级相差不可超过1级',
            		  type: 'warning'
            		});
            		this.form.grade = ''
            		return
            	}
            	this.form.gradeOpt.forEach(item => {
            		if(item.level == e){
            			this.form.gradeId = item.id
            		}
            	})
            	this.form.grade = e
            },
            applyRankChange(e) {
            	if(e == ''){
            		this.form.applyRank = ''
            		this.form.grade = ''
            		this.form.regFee = ''
            		return
            	}
            	if(this.form.grade >= e){
            		this.$message({
            		  message: '原等级必须低于报考等级',
            		  type: 'warning'
            		});
            		this.form.applyRank = ''
            		this.form.regFee = ''
            		return;
            	}
            	var leval = 4
            	if(e > leval && !this.form.grade) {
            		this.$message({
            		  message: '原等级不能为空',
            		  type: 'warning'
            		});
            		this.form.applyRank = ''
            		this.form.regFee = ''
            		return
            	}
            	if(e > leval && e - this.form.grade > 1) {
            		this.$message({
            		  message: '原等级与报考等级相差不可超过1级',
            		  type: 'warning'
            		});
            		this.form.applyRank = ''
            		this.form.regFee = ''
            		return
            	}
            	this.form.gradeOpt.forEach(item => {
            		if(item.level == e){
            			this.form.applyRankId = item.id
            			this.form.regFee = item.price
                        this.form.should = item.price
            		}
            	})
            	this.form.applyRank = e
            },
        }
    }    
</script>

<style scoped="scoped">
    .signDraft{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 30px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 报名信息部分 */
    .info {
        font-size: 18px;
        font-weight: bold;
        width: 150px;
        text-align: right;
        padding-bottom: 30px;
    }
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-select {
        width: 300px;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .form ::v-deep .el-textarea__inner{
        width: 300px;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333333;
        border-color:#ccc;
        /* cursor: default; */
    }
    /* 修改,删除,提交 按钮 */
    .edit{
        display: inline-block;
        border-radius: 2px;
        background-color: #18BC9C;
        color: #FFFFFF;
    }
    .del{
        display: inline-block;
        border-radius: 2px;
    }
    .Submit{
        display: inline-block;
        outline: none;
        border-radius: 2px;
        background: #FF7300;
        color: #FFFFFF;
    }
    /* 底部 */
    .buttom{
        height: 20px;
        line-height: 20px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
